
.symbolClass {
    stroke: #1976d2;
    stroke-width: 0.6px;
    fill: none;
    cursor: pointer;
}
.nonActiveClass {
    fill:gainsboro;
    stroke: black;
    stroke-width: 0.6px;
}
.nonActiveRecClass {
    fill: transparent;
    stroke: #1976d2;
    stroke-width: 0.6px;
}
.symbolBoundaryclass:active {
    stroke: none;
    fill: paleturquoise;
    cursor: pointer;
}
.activeClass {
    fill: paleturquoise;
    stroke: #1976d2;
    cursor: pointer;
    stroke-width: 0.6px;
}
.symbolBoundaryclass {
    stroke: none;
    cursor: pointer;
    fill: transparent;
}

.symbolLabelClass {
    background-color: #555;
    color: #fff;
    text-align: left;
    font-size: 10px;
    font-weight: bold;
}
.pinsLabelClass {
    background-color: #555;
    color: #fff;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
}
.activePinsLabelClass {
    background-color: #555;
    color: #fff;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}
.functionalLabelClass {
    background-color: #555;
    color: #fff;
    text-align: left;
    font-size: 15px;
    font-weight: bold;
}
.symbolPortClass {
    fill: #000000;
}

.trialClass {
    fill:#19FEFF
}

.blockDiagramLineClass {
    fill: none;
    stroke: #000000;

}